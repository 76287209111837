<template>
  <div class="alarmCont">
    <div class="listheader">
      <div class="titleName">SOS报警</div>
      <span>合计：{{storesTotal}}</span>
    </div>
    <div class="alarm_panel">
      <div v-if="sos" class="listcount">
        <template v-if="stores.length">
          <div
            v-for="(storesItem,index) in stores"
            :key="index"
            class="list"
            @click="storesClick(storesItem)"
          >
            <div class="hotelName">{{showName(storesItem.hotelName)}}</div>
            <span class="Num">{{storesItem.count}}</span>
          </div>
        </template>
        <template v-else>
          <div class="noData flex flex-center align-center">暂无 SOS 报警</div>
        </template>
      </div>
      <div v-else class="storesDetails" @click="showAll">
        <div class="storesName" v-if="storesName">{{showName(storesName)}}</div>
        <div class="detailsListCont" >
          <!-- <img src="../../assets/loading.gif" alt="" v-if="sosFlag"> -->
          <div v-if="!sosFlag">
            <div v-for="(roomItem,index) in roomList" :key="index">
              <!-- 2551 -->
              {{roomItem.floorName}}{{roomItem.roomNumber}}
              房
            </div>
          </div>
          <div
            v-if="!roomList||roomList.length==0"
            class="noData2 flex flex-center align-center"
          >暂无 SOS 报警</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_SosStores, GET_Room, GET_SOS_TYPE_1 } from "@/api";
import { showName } from "@/api/common";
export default {
  props: {
    roleType: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      stores: [], //sos门店
      sos: true,
      sosFlag: false,
      storesName: "", //门店名
      roomList: [],
    };
  },
  computed: {
    storesTotal() {
      if (this.roleType == 0) {
        return this.stores.reduce(function (sum, item) {
          let val = item.count ? item.count : 0;
          return sum + val;
        }, 0);
      } else {
        return this.roomList.length;
      }
    },
  },
  created() {
    if (this.roleType == 0) {
      this.getSosStores();
      this.sosInter = null;
      this.sosInter = setInterval(() => {
        this.getSosStores();
      }, 5000);
    } else {
      this.sos = false;
      this.GET_Room_data();
      this.offlineInter = null;
      this.offlineInter = setInterval(() => {
        this.GET_Room_data();
      }, 5000);
    }
  },
  beforeDestroy() {
    clearInterval(this.sosInter);
  },
  methods: {
    showAll() {
      if (this.roleType == 0) {
        this.sos = true;
        this.sosFlag = false;
      }
    },
    async GET_Room_data() {
      // this.sosFlag=true
      try {
        let res = await GET_SOS_TYPE_1();
        this.storesName = res.data.data.hotelName;
        this.roomList = res.data.data.roomList;
        this.sosFlag = false;
      } catch (error) {
        this.sosFlag = false;
      }
    },
    showName(val) {
      return showName(val);
    },
    //sos报警门店
    async getSosStores(data) {
      let res = await GET_SosStores(data);

      this.stores = res.data.data;
    },
    // 点击报警门店事件
    storesClick(item) {
      this.sos = false;
      this.sosFlag = true;
      this.storesName = item.hotelName;
      this.getRoom(item);
    },
    //sos报警房间
    async getRoom(data) {
      let res = await GET_Room(data);
      this.roomList = res.data.data;
      this.sosFlag = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
@import "@/assets/mdKanban/listData.scss";
</style>
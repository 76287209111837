var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarmCont" }, [
    _c("div", { staticClass: "listheader" }, [
      _c("div", { staticClass: "titleName" }, [_vm._v("SOS报警")]),
      _c("span", [_vm._v("合计：" + _vm._s(_vm.storesTotal))]),
    ]),
    _c("div", { staticClass: "alarm_panel" }, [
      _vm.sos
        ? _c(
            "div",
            { staticClass: "listcount" },
            [
              _vm.stores.length
                ? _vm._l(_vm.stores, function (storesItem, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "list",
                        on: {
                          click: function ($event) {
                            return _vm.storesClick(storesItem)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "hotelName" }, [
                          _vm._v(_vm._s(_vm.showName(storesItem.hotelName))),
                        ]),
                        _c("span", { staticClass: "Num" }, [
                          _vm._v(_vm._s(storesItem.count)),
                        ]),
                      ]
                    )
                  })
                : [
                    _c(
                      "div",
                      { staticClass: "noData flex flex-center align-center" },
                      [_vm._v("暂无 SOS 报警")]
                    ),
                  ],
            ],
            2
          )
        : _c(
            "div",
            { staticClass: "storesDetails", on: { click: _vm.showAll } },
            [
              _vm.storesName
                ? _c("div", { staticClass: "storesName" }, [
                    _vm._v(_vm._s(_vm.showName(_vm.storesName))),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "detailsListCont" }, [
                !_vm.sosFlag
                  ? _c(
                      "div",
                      _vm._l(_vm.roomList, function (roomItem, index) {
                        return _c("div", { key: index }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(roomItem.floorName) +
                              _vm._s(roomItem.roomNumber) +
                              "\n            房\n          "
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                !_vm.roomList || _vm.roomList.length == 0
                  ? _c(
                      "div",
                      { staticClass: "noData2 flex flex-center align-center" },
                      [_vm._v("暂无 SOS 报警")]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }